import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExperienceService {
  constructor(public http: HttpClient) {}
  baseUrl = environment.baseUrl;

  experienceAdd(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.post(this.baseUrl + `/createExperience`, obj, httpOptions);
  }

  experienceList(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.get(
      this.baseUrl +
        `/getExperienceList?limit=${obj.limit}&offset=${obj.offset}&search=${obj.search}&type=${obj.type}`,
      httpOptions
    );
  }

  experienceDetail(obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.get(
      this.baseUrl + `/getExperienceDetail/${obj.id}`,
      httpOptions
    );
  }

  experienceUpdate(id: number, obj: any, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.patch(
      this.baseUrl + `/updateExperience/${id}`,
      obj,
      httpOptions
    );
  }

  experienceDelete(id: number, token: any) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        accessToken: token,
      }),
    };
    return this.http.delete(
      this.baseUrl + `/deleteExperience/${id}`,
      httpOptions
    );
  }
}
