import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PreferencesService } from 'src/app/services/preferences.service';
import { ExperienceService } from 'src/app/services/experience.service';

@Component({
  selector: 'app-grwm-add',
  templateUrl: './grwm-add.component.html',
  styleUrls: ['./grwm-add.component.css'],
})
export class GrwmAddComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  token: any;
  roleList: any;
  addsubadminForm!: FormGroup;
  submitted: any;
  menuArr: any = [];
  menuErr = true;
  menuArr1: any = [];
  blankImg: any = false;
  image: any = '';
  isLoading: boolean = false;

  constructor(
    public router: Router,
    private experienceService: ExperienceService,
    private toastr: ToastrService,
    public formBuilder: FormBuilder,
    private prefrencesService: PreferencesService
  ) {}

  ngOnInit(): void {
    this.addsubadminForm = this.formBuilder.group({
      title: [
        '',
        [Validators.required, this.prefrencesService.noSpacesValidator],
      ],
      location: [
        '',
        [Validators.required, this.prefrencesService.noSpacesValidator],
      ],
      url: [
        '',
        [
          Validators.required,
          Validators.pattern(/^(https?:\/\/)?([\w.]+\.\w{2,})(\/.*)?$/i),
        ],
      ],
      description: [
        '',
        [Validators.required, this.prefrencesService.noSpacesValidator],
      ],
      image: ['', [Validators.required]],
      isActive: [true, Validators.required],
      order: [1, [Validators.required, Validators.min(1)]],
    });
  }

  get addSub() {
    return this.addsubadminForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;
    if (this.addsubadminForm.invalid) {
      this.blankImg == true;
      this.isLoading = false;
      return;
    }
    if (this.image === '') {
      alert('Please Upload Image');
    }
    this.token = localStorage['token'];
    var formData = new FormData();
    console.log(typeof this.addsubadminForm.get('isActive')?.value);
    formData.append('title', this.addsubadminForm.get('title')?.value);
    formData.append('location', this.addsubadminForm.get('location')?.value);
    formData.append('url', this.addsubadminForm.get('url')?.value);
    formData.append('isActive', this.addsubadminForm.get('isActive')?.value);
    formData.append('isGRWM', true.toString());
    formData.append('order', this.addsubadminForm.get('order')?.value);
    formData.append(
      'description',
      this.addsubadminForm.get('description')?.value
    );
    formData.append('image', this.image);
    this.experienceService.experienceAdd(formData, this.token).subscribe({
      next: (res: any) => {
        if (res && res.status == 200) {
          this.toastr.success(res.message);
          this.router.navigate(['/grwm-list']);
          this.addsubadminForm.reset();
        } else {
          this.toastr.error(res?.message);
        }
      },
      error: (res) => {
        this.toastr.error(res.error.message);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  onImageSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      this.image = event.target.files[0];
    }
  }

  resetFileInput() {
    // Reset the file input to clear the selected file
    this.fileInput!.nativeElement.value = '';
  }
}
