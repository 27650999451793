<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <form [formGroup]="addsubadminForm">
        <div class="globalheading dflex dflex_bet">
            <h2>
                <span routerLink="/grwm-list" class="breadcumbs">GRWM</span> <span class="navigation-arrow">&gt;</span>
                <span>Add GRWM</span>
            </h2>
            <div class="addbtnhead">
                <div class="page-header-right">
                    <div class="common_btn">
                        <button class="export-btn" [routerLink]="['/grwm-list']" [disabled]="isLoading">Cancel</button>
                        <button (click)="onSubmit()" [disabled]="isLoading">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-admin-add-form">
            <div class="add-data-form">
                <h2>Basic Information</h2>
                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Title <span>*</span></label>
                                <span class="your-name">
                                    <input name="your-name" value="" size="40" class="form-control" aria-required="true"
                                        aria-invalid="false" type="text" autocomplete="off" placeholder="Title"
                                        formControlName="title"
                                        [ngClass]="{'is-invalid': submitted && addSub['title'].errors}" />
                                    <div class="errorDiv" *ngIf="submitted && addSub['title'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['title'].errors['required']">
                                            Title is required
                                        </div>
                                        <div *ngIf="addSub['title'].errors['noSpaces']">Data cannot be spaces only.
                                        </div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Location <span>*</span></label>
                                <span class="your-name"><input name="your-name" value="" size="40" class="form-control"
                                        aria-required="true" aria-invalid="false" type="text" autocomplete="off"
                                        placeholder="Location" formControlName="location"
                                        [ngClass]="{'is-invalid': submitted && addSub['location'].errors}"
                                        autocomplete='off' id="email" required />
                                    <div class="errorDiv" *ngIf="submitted && addSub['location'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['location'].errors['required']">
                                            Location is required
                                        </div>
                                        <div *ngIf="addSub['location'].errors['noSpaces']">Data cannot be spaces only.
                                        </div>
                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-group full-input-wrap passwordfield">
                            <p>
                                <label>URL <span>*</span></label>
                                <span class="your-name"><input name="your-name" value="" size="40"
                                        class="form-control passwordinput" aria-required="true" aria-invalid="false"
                                        type="text" autocomplete="off" placeholder="URL" formControlName="url"
                                        [ngClass]="{'is-invalid': submitted && addSub['url'].errors}" autocomplete='off'
                                        required />
                                    <div class="errorDiv" *ngIf="submitted && addSub['url'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['url'].errors['required']">
                                            URL is required
                                        </div>
                                        <div class="error" *ngIf="addSub['url'].errors['pattern']">
                                            Please enter a valid url.
                                        </div>
                                        <div *ngIf="addSub['url'].errors['noSpaces']">Data cannot be spaces only.</div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group full-input-wrap passwordfield">
                            <p>
                                <label>Image <span>*</span></label>
                                <span class="your-name">
                                    <input #fileInput name="your-name" value="" size="40"
                                        class="form-control passwordinput" aria-required="true" aria-invalid="false"
                                        type="file" autocomplete="off" placeholder="Image" formControlName="image"
                                        [ngClass]="{'is-invalid': submitted && addSub['image'].errors}"
                                        autocomplete='off' (change)="onImageSelected($event)" />
                                    <div class="errorDiv" *ngIf="submitted && addSub['image'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['image'].errors['required']">
                                            Image is required
                                        </div>
                                        <div class="error" *ngIf="blankImg">Image is required</div>
                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group full-input-wrap passwordfield">
                            <p>
                                <label>Description <span>*</span></label>
                                <span class="your-name">

                                    <textarea rows="4" cols="50" name="your-name" value="" size="40"
                                        class="form-control passwordinput" aria-required="true" aria-invalid="false"
                                        autocomplete="off" placeholder="Description" formControlName="description"
                                        [ngClass]="{'is-invalid': submitted && addSub['description'].errors}"
                                        autocomplete='off' required>
                         </textarea>
                                    <div class="errorDiv" *ngIf="submitted && addSub['description'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['description'].errors['required']">
                                            Description is required
                                        </div>
                                        <div *ngIf="addSub['description'].errors['noSpaces']">Data cannot be spaces
                                            only.</div>

                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Is Active <span>*</span></label>
                                <span class="your-name">
                                    <select name="isActive" class="form-control" aria-required="true"
                                        aria-invalid="false" formControlName="isActive"
                                        [ngClass]="{'is-invalid': submitted && addSub['isActive'].errors}" required>
                                        <option value={{true}} selected>Yes</option>
                                        <option value={{false}}>No</option>
                                    </select>
                                    <div class="errorDiv" *ngIf="submitted && addSub['isActive'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['isActive'].errors['required']">
                                            Is Active selection is required
                                        </div>
                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="form-group full-input-wrap">
                            <p>
                                <label>Order <span>*</span></label>
                                <span class="your-name">
                                    <input type="number" name="order" class="form-control" aria-required="true"
                                        aria-invalid="false" formControlName="order"
                                        [ngClass]="{'is-invalid': submitted && addSub['order'].errors}" required>
                                    <div class="errorDiv" *ngIf="submitted && addSub['order'].errors"
                                        class="invalid-feedback">
                                        <div class="error" *ngIf="addSub['order'].errors['required']">
                                            Order is required
                                        </div>
                                        <div class="error" *ngIf="addSub['order'].errors['min']">
                                            Order should be greater than or equal to 1
                                        </div>
                                    </div>
                                </span>
                            </p>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    </form>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->