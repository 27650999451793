import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { CustomerDetailComponent } from './components/customers/customer-detail/customer-detail.component';
import { CustomerListComponent } from './components/customers/customer-list/customer-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FaqAddComponent } from './components/faq/faq-add/faq-add.component';
import { FaqEditComponent } from './components/faq/faq-edit/faq-edit.component';
import { FaqListComponent } from './components/faq/faq-list/faq-list.component';
import { LoginComponent } from './components/login/login.component';
import { ChangePasswordComponent } from './components/setting/change-password/change-password.component';
import { PrivacyPolicyComponent } from './components/static-content/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './components/static-content/terms-condition/terms-condition.component';
import { AuthGuard } from './services/auth.guard';
import { EventListComponent } from './components/event-management/event-list/event-list.component';
import { eventAddComponent } from './components/event-management/event-add/event-add.component';
import { EventEditComponent } from './components/event-management/event-edit/event-edit.component';
import { HobbieAddComponent } from './components/preferences/Hobbie/hobbie-add/hobbie-add.component';
import { HobbieEditComponent } from './components/preferences/Hobbie/hobbie-edit/hobbie-edit.component';
import { HobbieListComponent } from './components/preferences/Hobbie/hobbie-list/hobbie-list.component';
import { FitnessEditComponent } from './components/preferences/fitness/fitness-edit/fitness-edit.component';
import { FitnessListComponent } from './components/preferences/fitness/fitness-list/fitness-list.component';
import { FitnessAddComponent } from './components/preferences/fitness/fitness-add/fitness-add.component';
import { SmokerAddComponent } from './components/preferences/smoker/smoker-add/smoker-add.component';
import { SmookerEditComponent } from './components/preferences/smoker/smoker-edit/smoker-edit.component';
import { SmookerListComponent } from './components/preferences/smoker/smoker-list/smoker-list.component';
import { UsuallyWakeUpAddComponent } from './components/preferences/usualy-wake-up/usually-wake-up-add/usually-wake-up-add.component';
import { UsuallyWakeUpEditComponent } from './components/preferences/usualy-wake-up/usually-wake-up-edit/usually-wake-up-edit.component';
import { UsuallyWakeUpListComponent } from './components/preferences/usualy-wake-up/usually-wake-up-list/usually-wake-up-list.component';
import { InterestListComponent } from './components/preferences/interests-management/interest-list/interest-list.component';
import { InterestAddComponent } from './components/preferences/interests-management/interest-add/interest-add.component';
import { InterestEditComponent } from './components/preferences/interests-management/interest-edit/interest-edit.component';
import { ValuesAddComponent } from './components/preferences/values-management/values-add/values-add.component';
import { PersonalityAddComponent } from './components/preferences/personalities-management/personalities-add/personalities-add.component';
import { PersonalityListComponent } from './components/preferences/personalities-management/personalities-list/personalities-list.component';
import { ValuesEditComponent } from './components/preferences/values-management/values-edit/values-edit.component';
import { ValueListComponent } from './components/preferences/values-management/values-list/values-list.component';
import { PersonalityEditComponent } from './components/preferences/personalities-management/personalities-edit/personalities-edit.component';
import { LifeGoalsAddComponent } from './components/preferences/life-goals/life-goals-add/life-goals-add.component';
import { LifeGoalsEditComponent } from './components/preferences/life-goals/life-goals-edit/life-goals-edit.component';
import { LifeGoalsListComponent } from './components/preferences/life-goals/life-goals-list/life-goals-list.component';
import { AlcoholConsumptionComponent } from './components/preferences/alcohol/alcohol-list/alcohol-list.component';
import { AlcoholAddComponent } from './components/preferences/alcohol/alcohol-add/alcohol-add.component';
import { AlcoholEditComponent } from './components/preferences/alcohol/alcohol-edit/alcohol-edit.component';
import { MaxionGoalsAddComponent } from './components/preferences/maxion-goals/maxion-goals-add/maxion-goals-add.component';
import { MaxionGoalsEditComponent } from './components/preferences/maxion-goals/maxion-goals-edit/maxion-goals-edit.component';
import { MaxionGoalsListComponent } from './components/preferences/maxion-goals/maxion-goals-list/maxion-goals-list.component';
import { MeetVibesAddComponent } from './components/preferences/meet-vibes/meet-vibes-add/meet-vibes-add.component';
import { MeetVibesEditComponent } from './components/preferences/meet-vibes/meet-vibes-edit/meet-vibes-edit.component';
import { MeetVibesListComponent } from './components/preferences/meet-vibes/meet-vibes-list/meet-vibes-list.component';
import { ConnectionsComponent } from './components/connections/connections.component';
import { NotificationListComponent } from './components/push-notification/notification-list/notification-list.component';
import { NotificationAddComponent } from './components/push-notification/notification-add/notification-add.component';
import { ContactUsComponent } from './components/static-content/contact-us/contact-us.component';
import { PromoCodeListComponent } from './components/promo-code/promo-code-list/promo-code-list.component';
import { PromoCodeAddComponent } from './components/promo-code/promo-code-add/promo-code-add.component';
import { CustomerFeedbackComponent } from './components/customers/customer-feedback/customer-feedback.component';
import { ManualMatchesComponent } from './components/manual-matches/manual-matches.component';
import { ReferralManagementComponent } from './components/referral-management/referral-management.component';
import { RegionListComponent } from './components/setting/rgt/region-list/region-list.component';
import { GroupListComponent } from './components/setting/rgt/group-list/group-list.component';
import { RolesComponent } from './components/setting/roles/roles.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PartnershipComponent } from './components/partnership/partnership.component';
import { ContentAddComponent } from './components/static-content/mobile/content-add/content-add.component';
import { ContentEditComponent } from './components/static-content/mobile/content-edit/content-edit.component';
import { ContentListComponent } from './components/static-content/mobile/content-list/content-list.component';
import { ExperienceListComponent } from './components/experiences/experience-list/experience-list.component';
import { ExperienceEditComponent } from './components/experiences/experience-edit/experience-edit.component';
import { ExperienceAddComponent } from './components/experiences/experience-add/experience-add.component';
import { GrwmAddComponent } from './components/grwm/grwm-add/grwm-add.component';
import { GrwmEditComponent } from './components/grwm/grwm-edit/grwm-edit.component';
import { GrwmListComponent } from './components/grwm/grwm-list/grwm-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { showSidebar: false },
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardComponent,
    pathMatch: 'full',
    data: { title: 'Dashboard Overview' },
  },
  {
    path: 'connections',
    canActivate: [AuthGuard],
    component: ConnectionsComponent,
    pathMatch: 'full',
    data: { title: 'All Connections' },
  },
  {
    path: 'push-notification',
    canActivate: [AuthGuard],
    component: NotificationListComponent,
    pathMatch: 'full',
    data: { title: 'Push Notification' },
  },
  {
    path: 'push-notification-add',
    canActivate: [AuthGuard],
    component: NotificationAddComponent,
    pathMatch: 'full',
    data: { title: 'Push Notification' },
  },
  {
    path: 'promo-code',
    canActivate: [AuthGuard],
    component: PromoCodeListComponent,
    pathMatch: 'full',
    data: { title: 'Promo Code' },
  },
  {
    path: 'promo-code-add',
    canActivate: [AuthGuard],
    component: PromoCodeAddComponent,
    pathMatch: 'full',
    data: { title: 'Promo Code' },
  },
  {
    path: 'customers-list',
    canActivate: [AuthGuard],
    component: CustomerListComponent,
    pathMatch: 'full',
    data: { title: 'Customer List' },
  },
  {
    path: 'refferal-list',
    canActivate: [AuthGuard],
    component: ReferralManagementComponent,
    pathMatch: 'full',
    data: { title: 'Refferal List' },
  },
  {
    path: 'feedback/:id',
    canActivate: [AuthGuard],
    component: CustomerFeedbackComponent,
    pathMatch: 'full',
    data: { title: 'Customer Feedback' },
  },
  {
    path: 'customer-detail/:id/:status',
    canActivate: [AuthGuard],
    component: CustomerDetailComponent,
    pathMatch: 'full',
    data: { title: 'Customer Detail' },
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent,
    pathMatch: 'full',
    data: { title: 'Change Password' },
  },
  {
    path: 'regions-list',
    canActivate: [AuthGuard],
    component: RegionListComponent,
    pathMatch: 'full',
    data: { title: 'Regions' },
  },
  {
    path: 'roles-list',
    canActivate: [AuthGuard],
    component: RolesComponent,
    pathMatch: 'full',
    data: { title: 'Roles' },
  },
  {
    path: 'groups-list/:id',
    canActivate: [AuthGuard],
    component: GroupListComponent,
    pathMatch: 'full',
    data: { title: 'Groups' },
  },
  {
    path: 'manual-match',
    canActivate: [AuthGuard],
    component: ManualMatchesComponent,
    pathMatch: 'full',
    data: { title: 'Manual Matches' },
  },
  {
    path: 'event-list',
    canActivate: [AuthGuard],
    component: EventListComponent,
    pathMatch: 'full',
    data: { title: 'Event-List' },
  },
  {
    path: 'event-add',
    canActivate: [AuthGuard],
    component: eventAddComponent,
    pathMatch: 'full',
    data: { title: 'Event-Add' },
  },
  {
    path: 'event-edit/:id',
    canActivate: [AuthGuard],
    component: EventEditComponent,
    pathMatch: 'full',
    data: { title: 'Event-Edit' },
  },
  {
    path: 'faq',
    canActivate: [AuthGuard],
    component: FaqListComponent,
    pathMatch: 'full',
    data: { title: 'faq' },
  },
  {
    path: 'faq-add',
    canActivate: [AuthGuard],
    component: FaqAddComponent,
    pathMatch: 'full',
    data: { title: 'Faq-Add' },
  },
  {
    path: 'faq-edit/:faq_id',
    canActivate: [AuthGuard],
    component: FaqEditComponent,
    pathMatch: 'full',
    data: { title: 'Faq-Edit' },
  },
  {
    path: 'privacy-policy',
    canActivate: [AuthGuard],
    component: PrivacyPolicyComponent,
    pathMatch: 'full',
    data: { title: 'Privacy Policy' },
  },
  {
    path: 'terms-condition',
    canActivate: [AuthGuard],
    component: TermsConditionComponent,
    pathMatch: 'full',
    data: { title: 'Terms & Condition' },
  },
  {
    path: 'contact-us',
    canActivate: [AuthGuard],
    component: ContactUsComponent,
    pathMatch: 'full',
    data: { title: 'Privacy Policy' },
  },
  {
    path: 'maxion-goals-list',
    canActivate: [AuthGuard],
    component: MaxionGoalsListComponent,
    pathMatch: 'full',
    data: { title: 'Maxion Goals List' },
  },
  {
    path: 'maxion-goals-add',
    canActivate: [AuthGuard],
    component: MaxionGoalsAddComponent,
    pathMatch: 'full',
    data: { title: 'Maxion Goals Add' },
  },
  {
    path: 'maxion-goals-edit/:id',
    canActivate: [AuthGuard],
    component: MaxionGoalsEditComponent,
    pathMatch: 'full',
    data: { title: 'Edit Education' },
  },
  {
    path: 'life-goals-list',
    canActivate: [AuthGuard],
    component: LifeGoalsListComponent,
    pathMatch: 'full',
    data: { title: 'Caste' },
  },
  {
    path: 'life-goals-add',
    canActivate: [AuthGuard],
    component: LifeGoalsAddComponent,
    pathMatch: 'full',
    data: { title: 'Add Caste' },
  },
  {
    path: 'life-goals-edit/:id',
    canActivate: [AuthGuard],
    component: LifeGoalsEditComponent,
    pathMatch: 'full',
    data: { title: 'Edit Religion' },
  },
  {
    path: 'wake-up-list',
    canActivate: [AuthGuard],
    component: UsuallyWakeUpListComponent,
    pathMatch: 'full',
    data: { title: 'wake-up' },
  },
  {
    path: 'wake-up-add',
    canActivate: [AuthGuard],
    component: UsuallyWakeUpAddComponent,
    pathMatch: 'full',
    data: { title: 'Add Native Lanuage' },
  },
  {
    path: 'wake-up-edit/:id',
    canActivate: [AuthGuard],
    component: UsuallyWakeUpEditComponent,
    pathMatch: 'full',
    data: { title: 'Edit Skin Tone' },
  },
  {
    path: 'smoker',
    canActivate: [AuthGuard],
    component: SmookerListComponent,
    pathMatch: 'full',
    data: { title: 'smoker' },
  },
  {
    path: 'smoker-add',
    canActivate: [AuthGuard],
    component: SmokerAddComponent,
    pathMatch: 'full',
    data: { title: 'Add Smoker' },
  },
  {
    path: 'smoker-edit/:id',
    canActivate: [AuthGuard],
    component: SmookerEditComponent,
    pathMatch: 'full',
    data: { title: 'Edit Smoker Type' },
  },
  {
    path: 'hobbies',
    canActivate: [AuthGuard],
    component: HobbieListComponent,
    pathMatch: 'full',
    data: { title: 'hobbie' },
  },
  {
    path: 'hobby-add',
    canActivate: [AuthGuard],
    component: HobbieAddComponent,
    pathMatch: 'full',
    data: { title: 'Add Create By' },
  },
  {
    path: 'hobby-edit/:id',
    canActivate: [AuthGuard],
    component: HobbieEditComponent,
    pathMatch: 'full',
    data: { title: 'Edit Create By' },
  },
  {
    path: 'fitness',
    canActivate: [AuthGuard],
    component: FitnessListComponent,
    pathMatch: 'full',
    data: { title: 'Fitness' },
  },
  {
    path: 'fitness-add',
    canActivate: [AuthGuard],
    component: FitnessAddComponent,
    pathMatch: 'full',
    data: { title: 'Add Fitness' },
  },
  {
    path: 'fitness-edit/:id',
    canActivate: [AuthGuard],
    component: FitnessEditComponent,
    pathMatch: 'full',
    data: { title: 'Edit Fitness' },
  },

  {
    path: 'alcohol-consumption',
    canActivate: [AuthGuard],
    component: AlcoholConsumptionComponent,
    pathMatch: 'full',
    data: { title: 'Alcohol Consumption' },
  },
  {
    path: 'alcohol-add',
    canActivate: [AuthGuard],
    component: AlcoholAddComponent,
    pathMatch: 'full',
    data: { title: 'Height' },
  },
  {
    path: 'alcohol-consumption-edit/:id',
    canActivate: [AuthGuard],
    component: AlcoholEditComponent,
    pathMatch: 'full',
    data: { title: 'Alcohol Consumption' },
  },
  {
    path: 'meet-vibes-list',
    canActivate: [AuthGuard],
    component: MeetVibesListComponent,
    pathMatch: 'full',
    data: { title: 'Meet Vibes' },
  },
  {
    path: 'meet-vibes-add',
    canActivate: [AuthGuard],
    component: MeetVibesAddComponent,
    pathMatch: 'full',
    data: { title: 'Meet Vibes' },
  },
  {
    path: 'meet-vibes-edit/:id',
    canActivate: [AuthGuard],
    component: MeetVibesEditComponent,
    pathMatch: 'full',
    data: { title: 'Meet Vibes' },
  },

  {
    path: 'personality',
    canActivate: [AuthGuard],
    component: PersonalityListComponent,
    pathMatch: 'full',
    data: { title: 'Personality' },
  },
  {
    path: 'personality-add',
    canActivate: [AuthGuard],
    component: PersonalityAddComponent,
    pathMatch: 'full',
    data: { title: 'Personality As' },
  },
  {
    path: 'personality-edit/:id',
    canActivate: [AuthGuard],
    component: PersonalityEditComponent,
    pathMatch: 'full',
    data: { title: 'Personality As' },
  },
  {
    path: 'interest',
    canActivate: [AuthGuard],
    component: InterestListComponent,
    pathMatch: 'full',
    data: { title: 'interest' },
  },
  {
    path: 'interest-add',
    canActivate: [AuthGuard],
    component: InterestAddComponent,
    pathMatch: 'full',
    data: { title: 'Annual Income' },
  },
  {
    path: 'interest-edit/:id',
    canActivate: [AuthGuard],
    component: InterestEditComponent,
    pathMatch: 'full',
    data: { title: 'Annual Income' },
  },
  {
    path: 'values-list',
    canActivate: [AuthGuard],
    component: ValueListComponent,
    pathMatch: 'full',
    data: { title: 'value' },
  },
  {
    path: 'values-add',
    canActivate: [AuthGuard],
    component: ValuesAddComponent,
    pathMatch: 'full',
    data: { title: 'value' },
  },
  {
    path: 'values-edit/:id',
    canActivate: [AuthGuard],
    component: ValuesEditComponent,
    pathMatch: 'full',
    data: { title: 'value' },
  },
  {
    path: 'payments-list',
    canActivate: [AuthGuard],
    component: PaymentComponent,
    pathMatch: 'full',
    data: { title: 'Payment List' },
  },
  {
    path: 'partnerships-list',
    canActivate: [AuthGuard],
    component: PartnershipComponent,
    pathMatch: 'full',
    data: { title: 'Partnership List' },
  },
  {
    path: 'mobile-content-list',
    canActivate: [AuthGuard],
    component: ContentListComponent,
    pathMatch: 'full',
    data: { title: 'Mobile Content List' },
  },
  {
    path: 'mobile-content-edit/:id',
    canActivate: [AuthGuard],
    component: ContentEditComponent,
    pathMatch: 'full',
    data: { title: 'Mobile Content Edit' },
  },
  {
    path: 'mobile-content-add',
    canActivate: [AuthGuard],
    component: ContentAddComponent,
    pathMatch: 'full',
    data: { title: 'Mobile Content Add' },
  },
  {
    path: 'experiences-list',
    canActivate: [AuthGuard],
    component: ExperienceListComponent,
    pathMatch: 'full',
    data: { title: 'Experiences' },
  },
  {
    path: 'experiences-add',
    canActivate: [AuthGuard],
    component: ExperienceAddComponent,
    pathMatch: 'full',
    data: { title: 'Experience-Add' },
  },
  {
    path: 'experiences-edit/:id',
    canActivate: [AuthGuard],
    component: ExperienceEditComponent,
    pathMatch: 'full',
    data: { title: 'Experience-edit' },
  },
  {
    path: 'grwm-list',
    canActivate: [AuthGuard],
    component: GrwmListComponent,
    pathMatch: 'full',
    data: { title: 'GRWM' },
  },
  {
    path: 'grwm-add',
    canActivate: [AuthGuard],
    component: GrwmAddComponent,
    pathMatch: 'full',
    data: { title: 'GRWM-Add' },
  },
  {
    path: 'grwm-edit/:id',
    canActivate: [AuthGuard],
    component: GrwmEditComponent,
    pathMatch: 'full',
    data: { title: 'GRWM-edit' },
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
