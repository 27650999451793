<app-header></app-header>

<!-- Middle Content CSS End Here  -->
<div class="middle-content-wrap">
    <!-- Dashboard HTML Start Here  -->
    <div class="globalheading dflex dflex_bet">
        <h2>
            Customers
        </h2>
        <div class="addbtnhead">
            <div class="page-header-right">
                <div class="search-wrap">
                    <input class="form-control" placeholder="Search" type="text" id="shares"
                        (keyup)="onKeyUpEvent($event)" />
                    <i class="fas fa-search"></i>
                </div>
                <div class="common_btn">
                    <button class="export-btn" (click)="onExport(isUserStatus)">
                        <img src="assets/images/export.svg" alt="" /> Export
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col-md-3 pr-0" (click)="isButtonActive('approved')">
            <button type="button" class="add-user-btn tab_btn w-100"
                [ngClass]="(isUserStatus=='approved')? '':'btn_active'">Approved User</button>
        </div>
        <div class="col-md-3 pl-0 pr-0" (click)="isButtonActive('unapproved')">
            <button type="button" class="add-user-btn tab_btn w-100"
                [ngClass]="(isUserStatus=='unapproved')? '':'btn_active'">Unapproved User</button>
        </div>
        <div class="col-md-3 pl-0 pr-0" (click)="isButtonActive('incomplete')">
            <button type="button" class="add-user-btn tab_btn w-100"
                [ngClass]="(isUserStatus=='incomplete')? '':'btn_active'">Incomplete User</button>
        </div>
        <div class="col-md-3 pl-0" (click)="isButtonActive('rejected')">
            <button type="button" class="add-user-btn tab_btn w-100"
                [ngClass]="(isUserStatus=='rejected')? '':'btn_active'">Rejected User</button>
        </div>
    </div>
    <div class="listingformat">
        <div class="tabledatawrap table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Username</th>
                        <th>Contact Number</th>
                        <th>Email Address</th>
                        <th> {{ (this.isUserStatus=='approved') ? 'Active Subscription(Lifetime/Monthly)' :'Emirates ID
                            Verification(Yes/No)'}}</th>
                        <th> Registration Date and Time</th>
                        <th *ngIf="isUserStatus=='approved'">Profile Created on</th>
                        <th>Profile Status</th>
                        <th>Step At</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="
              let item of  (userList | paginate:{id: 'delivered', itemsPerPage: total, currentPage: p , totalItems: count} );let i = index;">
                        <td>{{ item.id}}</td>
                        <td>{{ item.name || "NA" }}</td>
                        <td class="width15">
                            {{ item.phone || "NA" }}
                        </td>
                        <td class="d-flx">
                            {{ item.email || "NA" }}
                            <div class="verfied-email-wrap" *ngIf="item?.verifyEmail == 'True'">
                                <img src="assets/images/email-verified.JPG" alt="" />
                            </div>
                            <div class="verfied-email-wrap" *ngIf="item?.verifyEmail == 'False'">
                                <img src="" alt="" />
                            </div>
                        </td>
                        <!-- <td>{{ item.age || "NA" }}</td> -->
                        <td> {{ (this.isUserStatus=='approved') ? (item.activeSubscription || 'N/A')
                            :(item.emiratesIDVerification || 'N/A')}}</td>
                        <td class="minwidth200">{{ item.registrationDate | date : 'medium' }}</td>
                        <td *ngIf="isUserStatus=='approved'">{{item.profileCreatedOn || ''}}</td>
                        <td>{{item?.profileStatus || 'N/A'}}</td>
                        <td>{{item?.profile_step || 'N/A'}}</td>
                        <td>
                            <div class="action-btns hide-profile-btn">
                                <button [routerLink]="['/customer-detail', item.id,isUserStatus]">
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                </button>
                                <div *ngIf="isUserStatus=='approved'" class="profile-btn">
                                    <button *ngIf="item?.status=='Active'"
                                        (click)="openModal(item?.id,'Block');blockUnblockUser='block'"
                                        data-toggle="modal" data-target="#blockUnblockUser" data-placement="top"
                                        title="Unblocked">
                                        <i class="fa fa-unlock" aria-hidden="true"></i>
                                    </button>

                                    <button *ngIf="item?.status=='Blocked'"
                                        (click)="openModal(item?.id,'Unblock');blockUnblockUser='unblock'"
                                        data-toggle="modal" data-target="#blockUnblockUser" data-placement="top"
                                        title="Blocked">
                                        <i class="fa fa-ban" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <button (click)="openModal(item?.id,'Remove');blockUnblockUser='delete'"
                                    data-toggle="modal" data-target="#blockUnblockUser" data-placement="top"
                                    title="Delete">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                <button *ngIf="isUserStatus!='unapproved' && isUserStatus!='incomplete'"
                                    (click)="openUnapproveModal(item?.id)" data-toggle="modal" data-placement="top"
                                    title="Move user back to waitlist">
                                    <i class="fa fa-undo" aria-hidden="true"></i>
                                </button>
                                <!-- Reject user -->
                                <button *ngIf="isUserStatus=='unapproved'" (click)="openRejectModal(item?.id)"
                                    data-toggle="modal" data-placement="top" title="Unapprove">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>

                                <div class="profile-btn">
                                    <button *ngIf="item.registrationCompleted == 'Yes' && isUserStatus=='unapproved'"
                                        (click)="openApprovedUnapprovedUserModal(item?.id)" tooltip="Approved icon"
                                        data-toggle="modal" data-target="#approveUnapproved">
                                        <i class="fa fa-check-circle"
                                            [ngClass]="(item?.verifyStatus=='No') ? 'pending':(item?.verifyStatus=='Yes' ? 'approved':'unapproved')"></i>
                                    </button>

                                    <button class="profilebtncomplete" *ngIf="item.registrationCompleted == 'No'"
                                        tooltip="Profile Not completed">
                                        Registration not completed
                                    </button>
                                </div>
                                <div class="feedbackbtn">
                                    <button type="button" [routerLink]="['/feedback', item.id]">View Feedback</button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="userList?.length==0">
                    <tr>
                        <td colspan="12">No Data Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-md-12 custompagination">
                <pagination-controls class="" previousLabel="Prev" nextLabel="Next" id="delivered"
                    (pageChange)="onTableDataChange($event)">
                </pagination-controls>
            </div>
        </div>
    </div>
    <!-- Dashboard HTML End Here  -->
</div>
<!-- Middle Content CSS End Here  -->

<!--Block/Unblock/Delete Popup -->
<div class="modal fade bd-example-modal-sm common_modal" id="blockUnblockUser" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog animated fadeInDown">
        <div class="modal-content">
            <div class="modal-header">
                <div class="popupheding">
                    {{blockUnblockUser | titlecase}}
                </div> <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="filtercard">
                    <p> Are you sure you want to {{blockUnblockUser}}
                        this user?</p>
                    <div class="common_btn">
                        <button data-dismiss="modal">Cancel</button>
                        <button (click)="blockUnblockDeleteAction()" id="closeM">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Delete Popup -->

<!-- Approve modal -->
<app-approve-modal [id]="approveModalId" [modalEvent]="approveEmitter"
    (approveUser)="approveUnapprovedf()"></app-approve-modal>

<!-- Reject modal -->
<app-reject-modal [id]="rejectModalId" [modalEvent]="rejectEmitter"
    (rejectUser)="updateUserApproval($event)"></app-reject-modal>

<app-unapprove-modal [id]="unApproveModalId" [modalEvent]="unApproveEmitter"
    (unapproveUser)="updateUserApproval($event)"></app-unapprove-modal>