import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExperienceService } from 'src/app/services/experience.service';
declare var $: any;

@Component({
  selector: 'app-grwm-list',
  templateUrl: './grwm-list.component.html',
  styleUrls: ['./grwm-list.component.css'],
})
export class GrwmListComponent {
  p: any = 1;
  count: any = 0;
  total: any = 10;
  token: any;
  subadminData: any;
  id: any;
  search: any = '';
  isChecked: any;
  subAdminId: any = [];
  masterSelected: boolean = false;
  exportList: any;
  userObject = '';

  constructor(
    public router: Router,
    private experienceService: ExperienceService,
    private toastr: ToastrService,
    private activateroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.experienceListing();
  }

  experienceListing() {
    let offset = this.p - 1;
    if (this.search != '') {
      offset = 0;
    } else {
      offset = this.p - 1;
    }
    let obj = {
      limit: 10,
      offset: offset,
      search: this.search,
      type: 'grwm',
    };
    this.token = localStorage['token'];
    this.experienceService
      .experienceList(obj, this.token)
      .subscribe((res: any) => {
        if (res && res.status == 200) {
          this.count = res.data.total_count;
          this.subadminData = res.data.data;
          this.userObject = '';
        } else if (
          res.status == 400 &&
          res.message == 'Please provide valid token'
        ) {
          this.toastr.error(res.message);
          this.router.navigate(['/login']);
        } else {
          this.toastr.success(res.message);
          alert(res.message);
        }
      });
  }

  onKeyUpEvent(event: any) {
    this.search = event.target.value;
    this.p = 1;
    this.experienceListing();
  }

  onTableDataChange(event: any) {
    this.p = event;
    this.masterSelected = false;
    this.experienceListing();
    // this.ngOnInit();
  }

  openModal(item: any) {
    this.id = item.id;
  }

  deletePop() {
    this.experienceService
      .experienceDelete(this.id, this.token)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.toastr.success(res.message);
          $('#deletModal').modal('hide');
        } else if (res.status == 400) {
          this.toastr.error(res.message);
        }
        this.experienceListing();
      });
  }
}
